<script setup lang="ts">
import {useSeoMeta, useRuntimeConfig} from "nuxt/app";
import ColoringCard from "~/components/ColoringCard.vue";
import {useHomeStore} from "~/stores/home";
import * as constants from "~/constants"
import Breadcrumb from "~/components/Breadcrumb.vue";
import {useUserStore} from "~/stores/user";

const config = useRuntimeConfig()
const hs = useHomeStore()
const us = useUserStore()
const name = constants.appName
const title = constants.appTitle
const desc = constants.appDescription
const isPWA = computed(() => process.client && window.isPWA)

useSeoMeta({
  title: title,
  description: desc,
  ogDescription: desc,
  ogTitle: title,
  ogImage: '/screenshot/default.png',
  twitterCard: 'summary_large_image',
})
const closed = ref(false)

us.setBC([])
</script>

<template>
  <div class="space-y-4 md:mt-4">
    <breadcrumb/>
    <div class="max-w-2xl mx-auto space-y-4">
      <div v-if="!isPWA && !closed" class="space-y-2">
        <h1 class="text-3xl font-bold font-pixel">{{ name }}</h1>
        <p>{{ desc }} <a href="/about" target="_blank" class="underline">About us!</a></p>
        <div class="absolute top-0 right-3 text-xs uppercase font-bold text-gray-500 cursor-pointer" @click="closed = true">Close</div>
      </div>
      <client-only>
        <div class="space-y-2">
          <div class="flex justify-between uppercase text-xs font-semibold items-center">
            <div>Your work</div>
            <nuxt-link to="/work" class="cursor-pointer text-white px-2 py-0.5 flex gap-2 items-center bg-green-500">
              <span>All</span>
              <div class="w-4 h-4 i-con-arrow-right"/>
            </nuxt-link>
          </div>
          <editor-current-work class="-mx-4"/>
        </div>
      </client-only>
      <div>
        <h2 class="font-pixel text-3xl font-bold uppercase">
          <nuxt-link to="/arts" title="Pixel Art">Community</nuxt-link>
        </h2>
        <p class="md:text-lg">Pixel art made by creators from worldwide!</p>
      </div>
      <div v-if="hs.r1.count" class="my-4 space-y-2">
        <h3 class="font-bold font-pixel">
          <nuxt-link title="8x8 Pixel art" to="/arts/size-8x8">8x8</nuxt-link>
        </h3>
        <div class="overflow-auto no-scrollbar -mx-4 px-2">
          <div class="flex flex-nowrap">
            <div v-for="item in hs.r1.results" :key="item.id" class="px-2 flex-none w-2/5 md:w-1/4">
              <coloring-card class="block" :value="item"/>
            </div>
            <div class="flex-none w-2"/>
          </div>
        </div>
      </div>
      <div v-if="hs.r2.count" class="my-4 space-y-2">
        <h3 class="font-bold font-pixel">
          <nuxt-link title="16x16 Pixel Art" to="/arts/size-16x16">16x16</nuxt-link>
        </h3>
        <div class="overflow-auto no-scrollbar -mx-4 px-2">
          <div class="flex flex-nowrap">
            <div v-for="item in hs.r2.results" :key="item.id" class="px-2 flex-none w-2/5 md:w-1/4">
              <coloring-card class="block" :value="item"/>
            </div>
            <div class="flex-none w-2"/>
          </div>
        </div>
      </div>
      <div v-if="hs.r3.count" class="my-4 space-y-2">
        <h3 class="font-bold font-pixel">
          <nuxt-link title="24x24 Pixel Art" to="/arts/size-24x24">24x24</nuxt-link>
        </h3>
        <div class="overflow-auto no-scrollbar -mx-4 px-2">
          <div class="flex flex-nowrap">
            <div v-for="item in hs.r3.results" :key="item.id" class="px-2 flex-none w-2/5 md:w-1/4">
              <coloring-card class="block" :value="item"/>
            </div>
            <div class="flex-none w-2"/>
          </div>
        </div>
      </div>
      <div v-if="hs.r4.count" class="my-4 space-y-2">
        <h3 class="font-bold font-pixel">
          <nuxt-link title="32x32 Pixel Art" to="/arts/size-32x32">32x32</nuxt-link>
        </h3>
        <div class="overflow-auto no-scrollbar -mx-4 px-2">
          <div class="flex flex-nowrap">
            <div v-for="item in hs.r4.results" :key="item.id" class="px-2 flex-none w-2/5 md:w-1/4">
              <coloring-card class="block" :value="item"/>
            </div>
            <div class="flex-none w-2"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
